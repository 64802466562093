.paginator {
    margin-top: 12px;

    .pagination {
        margin: unset !important;
    }

    .page-link {
        padding: 5px 12px !important;
    }

    .text-center {
        font-style: italic;
        color: gray;
    }
}