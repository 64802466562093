.hfmn-table {
    border-spacing: 0px 8px;
    border-collapse: unset;

    .hfmn-table-header {
        display: flex;

        @media (max-width: 991px) {
            display: grid;
            grid-template-columns: repeat(2, 50%);

            th:first-of-type {
                width: unset !important;
                word-break: break-word;
            }
    
            th:last-of-type {
                flex: unset !important;
                word-break: break-word;
            }
        }

        th {
            flex: 1;
            color: gray;

            a {
                color: gray;
                font-weight: 200;
                text-transform: uppercase;
            }
        }

        th:first-of-type {
            flex: unset !important;
            width: 250px;
            border-right: 1px solid lightgray;
            text-align: left;
        }

        th:last-of-type {
            padding-left: 16px;
        }
    }

    .hfmn-table-data {
        border: unset !important;
        background: whitesmoke;
        display: flex;
        margin-bottom: 8px;

        td {
            flex: 1;
            border: none;
            vertical-align: middle;
            padding: 4px 16px;
        }

        td:first-of-type {
            flex: unset !important;
            width: 250px;
            border-right: 1px solid lightgray;
            text-align: left;
        }

        td:last-of-type {
            font-weight: 550;
        }

        @media (max-width: 991px) {
            display: grid;
            grid-template-columns: repeat(2, 50%);

            td:first-of-type {
                width: unset !important;
                word-break: break-word;
            }
    
            td:last-of-type {
                flex: unset !important;
                word-break: break-word;
            }
        }
    }
}

.hfmn-table-top {
    .hfmn-table-header {
        th:first-of-type {
            width: 320px !important;

            @media (max-width: 991px) {
                width: unset !important;
            }
        }
    }

    .hfmn-table-data {
        td:first-of-type {
            width: 320px !important;

            @media (max-width: 991px) {
                width: unset !important;
            }
        }
    }
}