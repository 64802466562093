.login {
    display: flex;
    flex-direction: column;
    position: relative;

    .inner-login {
        max-width: 700px;
        margin: auto;
        text-align: center;

        .icon {
            font-size: 80px;
        }

        .welcome {
            color: gray;
            font-size: 12px;
            font-style: italic;
        }

        .checks {
            font-size: 13px;
        }

        .actions {
            display: flex;
            flex-direction: column;
            margin-top: 12px;
        }
    }

    .register {
        position: absolute;
        bottom: 1rem;
        width: 100%;
        text-align: center;
    }
}


.form-label-group {
    position: relative;
    margin-bottom: 1rem;
    text-align: left;
  }
  
  .form-label-group input,
  .form-label-group label {
    height: 3.125rem;
    padding: .75rem;
  }
  
  .form-label-group label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0; /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    pointer-events: none;
    cursor: text; /* Match the input under the label */
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
  }
  
  .form-label-group input::-webkit-input-placeholder {
    color: transparent;
  }
  
  .form-label-group input::-moz-placeholder {
    color: transparent;
  }
  
  .form-label-group input:-ms-input-placeholder {
    color: transparent;
  }
  
  .form-label-group input::-ms-input-placeholder {
    color: transparent;
  }
  
  .form-label-group input::placeholder {
    color: transparent;
  }
  
  .form-label-group input:not(:-moz-placeholder-shown) {
    padding-top: 1.25rem;
    padding-bottom: .25rem;
  }
  
  .form-label-group input:not(:-ms-input-placeholder) {
    padding-top: 1.25rem;
    padding-bottom: .25rem;
  }
  
  .form-label-group input:not(:placeholder-shown) {
    padding-top: 1.25rem;
    padding-bottom: .25rem;
  }
  
  .form-label-group input:not(:-moz-placeholder-shown) ~ label {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: 12px;
    color: #777;
  }
  
  .form-label-group input:not(:-ms-input-placeholder) ~ label {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: 12px;
    color: #777;
  }
  
  .form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: 12px;
    color: #777;
  }
  
  .form-label-group input:-webkit-autofill ~ label {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: 12px;
    color: #777;
  }