main {
    flex: 1;
    overflow: hidden;
}

#nav-bars {
    margin-right: 24px;
    cursor: pointer;
}

.spacer {
    flex: 1 1 auto;
}

#sidenav {
    width: 55px;
    background: #efefef;
    height: 100%;
    transition: width 0.6s;
    display: flex;
    flex-direction: column;

    .active {
        background: rgba($color: #000000, $alpha: 0.06);
        color: $primary !important;
        border-left: 2px solid $primary;
        padding: 16px !important;
    }

    .nav-item {
        display: flex;
        column-gap: 12px;
        align-items: center;
        min-height: 30px;
        padding: 16px;
        cursor: pointer;
        color: black;

        i {
            font-size: 24px;
        }

        .nav-title {
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
        }

        .title-none {
            opacity: 0;
        }

        &:hover {
            background: rgba($color: #002742, $alpha: 0.6);
            color: white;
        }
    }

    .logged-user {
        display: flex;
        column-gap: 12px;
        align-items: center;
        min-height: 30px;
        background: #002742;
        padding: 10px;
        color: white;
        cursor: pointer;

        .user-back {
            padding: 5px 8px;
            background: lightgray;
            border-radius: 50%;
        }

        i {
            font-size: 24px;
        }

        ul {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;

            li {
                flex: 1;
                padding: unset;
                display: flex;

                a {
                    flex: 1;
                    display: flex;
                    align-items: center;

                    span {
                        flex: 1;
                    }
                }

                .sign-out {
                    display: flex;
                    align-items: center;
                    column-gap: 12px;
                    color: $primary;
                }
            }

            .dropdown-toggle::after {
                margin-left: unset !important;
                content: "";
                border: none !important;
            }
        }

        .nav-title {
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
        }

        .title-none {
            opacity: 0;
        }
    }
}

.overflow {
    overflow: auto;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-1 {
    flex: 1;
}
