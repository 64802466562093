.navigation {
    display: flex;
    align-items: center;
    padding: 8px 24px;
    box-shadow: 0px 0px 5px 3px rgb(0 0 0 / 15%);
    position: relative;
    z-index: 9;
    background: #002742;

    .login {
        display: flex;
        flex-direction: row;
        column-gap: 24px;
    }

    img {
        max-width: 150px;

        @media (max-width: 767px) {
            max-width: 100px;
        }
    }
}

.sub-navigation {
    display: flex;
    align-items: center;
    padding: 12px 24px;
    //box-shadow: 0px 0px 5px 3px rgb(0 0 0 / 15%);
    background: whitesmoke;
    column-gap: 8px;

    h3 {
        margin: unset;
        white-space: nowrap;
        flex: 1;
    }

    @media(max-width: 767px) {
        .spacer {
            display: none;
        }
    }
}

.bar1,
.bar2,
.bar3 {
    width: 24px;
    height: 3px;
    background-color: white;
    margin: 3px 0;
    transition: 0.4s;
    border-radius: 0 10px 10px 0;
}

.bar1 {
    width: 16px;
}

.bar3 {
    width: 18px;
}

.change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
    width: 24px;
    border-radius: 10px;
}

.change .bar2 {
    opacity: 0;
}

.change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-2px, 0px);
    width: 24px;
    border-radius: 10px;
}
