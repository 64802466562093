// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';
@import "dashboard";
@import "article";
@import "table";
@import "login";
@import "sidebar";
@import "toggle";
@import "search";
@import "paginator";

// Bootstrap & Font Awseome
@import '~bootstrap/scss/bootstrap';
@import "lib/font-awesome-4.7.0/font-awesome";
//@import "lib/jquery-confirm.min.css";

//navbar
@import "navbar";

//sidebar
//@import "sidebar";
@import "icon-sidebar";

//category
@import "category/index";

//user
@import "user/index";

@media(max-width:767px) {
    input {
        font-size: 16px !important;
    }
}

html {
    height: 100%;

    body {
        height: 100%;
        display: flex;
        flex-direction: column;

        #app {
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }
    }
}

a:not(h1 a) {
    text-decoration: none;
}

.message {
    position: absolute;
    top: 25px;
    right: 25px;
}

/* auth */

#account_sidebar {
    li {
        padding: 6px 10px 6px 0;
    }
}

.affix {
    position: -webkit-sticky;
    position: sticky;
    top: 30px;
}

.btn-primary {
    color: white;

    &:hover {
        background: rgba($color: $primary, $alpha: 0.7);
        color: white;
    }
}

.jconfirm-open {
    position: absolute;
    z-index: 999;
    background: rgba($color: #000000, $alpha: 0.4);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .jconfirm-box {
        position: relative;
    }

    .jconfirm-scrollpane {
        padding: 24px;
        background: white;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f;

        // .jconfirm-closeIcon {
        //     display: block !important;
        //     position: absolute;
        //     top: 0px;
        //     right: 0px;
        // }

        .jconfirm-title {
            display: block !important;
            font-weight: bold;
            margin-bottom: 8px;
            font-size: 15px;
        }

        .jconfirm-holder {
            padding: unset !important;
        
            .jconfirm-buttons {
                margin-top: 16px;
                display: flex;
                column-gap: 24px;
            }
        }
    }
}