.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: white;
  overflow: hidden;
  padding: 16px;
  //border-radius: 10px;
  //box-shadow: 3px 3px 3px 2px rgb(0 0 0 / 7%);

  .scroll-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    row-gap: 6px;

    h5 {
      color: gray;
    }
  }
}