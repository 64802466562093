.dash {
    background: whitesmoke;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
}

.dashboard_header {
    h2 {
        margin-bottom: 4px;
        font-weight: bold;
    }

    span {
        color: lightgray;
        font-style: italic;
    }
}

.dashboard {
    height: 100%;

    .state {
        min-height: 100px;
        background: white;
        //border-radius: 10px;
        display: flex;
        align-items: center;
        box-shadow: 0px 4px 4px 2px rgb(0 0 0 / 5%);

        .row {
            width: 100%;
            margin: unset;

            @media (max-width: 767px) {
                row-gap: 16px;
                padding: 16px;
            }
        }

        .item {
            column-gap: 12px;

            .icon {
                width: 50px;
                height: 50px;
                background: white;
                //border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    font-size: 24px;
                }
            }

            .detail {
                span {
                    color: gray;
                    font-size: 12px;
                    font-style: italic;
                }

                h4 {
                    margin: 0;
                    font-weight: bold;
                }
            }
        }
    }

    .activity {
        height: 100%;
        overflow: hidden;
        background: white;
        //border-radius: 10px;
        padding: 16px;
        box-shadow: 0px 4px 4px 2px rgb(0 0 0 / 5%);
        display: flex;
        flex-direction: column;

        .logs {
            flex: 1;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}

.dashboard_user {
    .icon {
        background: rgba($color: #ff6d04, $alpha: 0.08) !important;

        i {
            color: #ff6d04;
        }
    }
}

.dashboard_category {
    .icon {
        background: rgba($color: #7042ff, $alpha: 0.08) !important;

        i {
            color: #7042ff;
        }
    }
}

.dashboard_article {
    .icon {
        background: rgba($color: #07b8fe, $alpha: 0.08) !important;

        i {
            color: #07b8fe;
        }
    }
}

.success-message {
    color: green;
}

.hint-message {
    color: gray;
}

.error-message {
    color: red;
}
