.user_header {
    display: flex;
    column-gap: 24px;
    align-items: center;

    @media (max-width: 767px) {
        align-items: center;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 5px;
    }

    h3 {
        margin: unset;
    }

    .total {
        font-size: 13px;
        font-style: italic;
        color: gray;
    }
}