.toggle {
    display: flex;
    align-items: center;
    column-gap: 12px;

    .toggle-item {
        background: $secondary;
        min-height: 30px;
        //border-radius: 20px;
        padding: 4px 16px;
        display: flex;
        align-items: center;
        column-gap: 6px;
        cursor: pointer;
        color: white;

        span {
            display: none;
        }
    }

    .active {
        background: $primary;

        span {
            display: block;
        }
    }
}