.search {
    input {
        //border-radius: 20px !important;
        border-radius: 0px !important;
        background: white !important;
    }

    button {
        //border-radius: 50%;
        border-radius: 0px !important;
        margin-left: -35px;
        z-index: 99 !important;
    }
}

#field_art {
    background: #ff3e33;
    color: white;
    border: unset;
    min-width: 150px;
    max-width: 170px;
    font-weight: 600;
    padding: 0 6px;
}