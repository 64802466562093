.article-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        overflow: auto;
    }

    @media (max-width: 991px) {
        overflow: auto;
        overflow-x: hidden;
    }

    .actions {
        display: flex;
        column-gap: 16px;
    }
}

.article {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.article-info {
    margin: unset !important;
    //flex: 1;
    height: 270px;
    display: flex;
    overflow: hidden;
    flex-wrap: unset !important;
    column-gap: 32px;

    @media (max-width: 767px) {
        height: auto;
        flex-wrap: wrap;
        overflow: auto;
    }

    @media (max-width: 1181px) {
        flex-wrap: wrap !important;
        overflow: auto;
    }

    .article-image {
        background-image: url("/images/placeholder.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        //border-radius: 10px;
        min-height: 200px;
        max-height: 200px;
        margin-top: 2rem;
        margin-bottom: 2rem;

        @media (max-width: 767px) {
            margin-bottom: 24px;
            min-height: 150px;
            max-height: 150px;
        }
    }

    .description {
        padding: 12px 0px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        height: 100%;

        @media (max-width: 991px) {
            height: unset;
        }

        label {
            font-size: 13px;
            font-style: italic;
            color: $secondary;
            margin-bottom: 4px;
        }

        span {

        }
    }

    .article-details {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;
        padding-left: 24px;

        @media (max-width: 767px) {
            padding-left: unset;
            overflow: unset;
        }

        @media (max-width: 991px) {
            height: unset;
            overflow: unset;
            overflow-x: hidden;
        }

        @media (max-width: 1181px) {
            overflow: unset;
        }

        .title {
            display: flex;
            align-items: center;

            h2 {
                margin: unset;
                font-weight: bold;
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            i {
                color: $primary;
                font-size: 24px;
                cursor: pointer;
            }
        }

        .number {
            color: gray !important;
            font-style: italic;

            @media (max-width: 991px) {
                margin-top: 1rem;
                margin-bottom: 1rem;
            }
        }

        @media (max-width: 767px) {
            padding: unset !important;
        }
    }
}

.article-tabs {
    flex: 1;
    overflow: hidden;
    margin-left: -16px;
    display: flex;
    flex-direction: column;

    .nav-tabs {
        border-bottom: unset !important;
    }

    .mobile-description {
        padding: 12px 0 12px 16px;
        text-align: justify;
    }

    .nav-tabs .nav-link {
        color: #cbcbcb;
        background-color: #f8fafc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: unset !important;

        .dot {
            width: 20px;
            height: 3px;
            background: #f8fafc;
            //border-radius: 20px;
        }

        &:hover {
            border: unset;

            span {
                color: $primary;
                margin-bottom: 5px;
            }
        }
    }

    .nav-tabs .nav-link.active {
        span {
            color: $primary;
            margin-bottom: 5px;
        }

        .dot {
            width: 20px;
            height: 3px;
            background: $primary;
            //border-radius: 20px;
        }
    }
}

.grid-view {
    flex: 1;
    display: grid;
    //grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
    grid-template-columns: repeat(auto-fit, minmax(190px, 195px));
    flex-shrink: 1;
    column-gap: 24px;
    row-gap: 24px;
    overflow: auto;

    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;

        .article {
            width: 100% !important;
        }
    }

    .article {
        min-height: 200px;
        display: flex;
        flex-direction: column;
        text-align: center;
        position: relative;

        .image {
            flex: 1;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            //border-radius: 20px;
            margin-bottom: 8px;
            //box-shadow: 3px 3px 3px 2px rgb(0 0 0 / 15%);
        }

        .art-title {
            h4 {
                margin: auto;
                font-size: 1.25rem;
                white-space: nowrap;
                width: 90%;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center;
            }
    
            span {
                color: gray;
                font-size: 12px;
                font-style: italic;
            }
        }
    }
}

.list-view {
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .article {
        display: flex;
        flex-direction: row;
        background: white;
        //border-radius: 10px;
        column-gap: 12px;
        align-items: center;
        padding: 8px;
        position: relative;

        .image {
            height: 40px;
            width: 40px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            //border-radius: 5px;
        }

        .art-title {
            h4 {
                margin: unset;
                font-size: 1.25rem;
                color: black;
            }
    
            span {
                color: gray;
                font-size: 12px;
                font-style: italic;
            }
        }

        &:hover {

            .art-title {
                h4 {
                    color: $primary;
                }
        
                span {
                    color: $primary;
                }
            }
        }
    }
}
