.category-item {
    display: flex;
    align-items: center;
    background: whitesmoke;
    padding: 8px;
    //border-radius: 10px;
    color: black;

    span {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:hover {
        color: $primary;
        background: rgba($color: $secondary, $alpha: 0.9);
    }
}

.category-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.breadcrumb {
    margin-bottom: unset !important;
}

.inner-category {
    display: flex;
    flex-direction: column;

    .inner-head {
        display: flex;
        align-items: center;
        column-gap: 24px;
        margin-bottom: 12px;

        .form {
            flex: 1;

            .form-group {
                max-width: 700px;
            }
        }
    }
}

.category {
    display: grid;
    grid-template-columns: 280px 1fr;
    column-gap: 24px;
    flex: 1;
    overflow: hidden;

    @media (max-width: 769px) {
        grid-template-columns: 200px 1fr;
    }

    @media (max-width: 524px) {
        grid-template-columns: 1fr;

        .sidebar {
            display: none ;
        }
    }

    .category_header {
        padding: 12px 0;
        display: flex;
        align-items: center;
        column-gap: 64px;

        form {
            flex: 1;
        }

        @media (max-width: 952px) {
            flex-direction: column;
            row-gap: 8px;
            align-items: flex-start;

            form {
                width: 100%;
            }
        }

        .title {
            h2 {
                margin: unset;
                font-weight: bold;
            }

            span {
                color: gray;
                font-style: italic;
                font-size: 12px;
            }
        }
    }
}

.no_article {
    color: gray;
    font-style: italic;
}
