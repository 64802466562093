// Body
$body-bg: #f8fafc;
$primary: #ff3e33 !default;
$secondary: #002742 !default;
$body-bg: #f5f5f5 !default;
$body-color: #333 !default;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;